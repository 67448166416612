<template>
    <language-switcher
        v-slot="{ links }"
        :class="{ maintenance: maintenanceMode == true }"
    >
        <div
            v-if="isMobile"
            class="mobile-switch"
        >
            <ul>
                <li @click="closeDropdown()">
                    <arrow-left-icon />
                    {{ $t('header.navbar.language.heading') }}
                </li>
                <li
                    v-for="link in links"
                    :key="link.langIndex"
                >
                    <router-link :to="link.url">
                        <label>
                            <div class="custom-checkbox">
                                <input
                                    type="radio"
                                    name="language"
                                    :checked="isCurrent(link.langIndex)"
                                    :value="link.langIndex"
                                >
                                <div class="checkmark">
                                    <check-icon />
                                </div>
                            </div>
                            <div class="text">
                                {{
                                    link.langIndex == 'ro'
                                        ? $t('header.navbar.language.roLabel')
                                        : $t('header.navbar.language.huLabel')
                                }}
                            </div>
                        </label>
                    </router-link>
                </li>
            </ul>
        </div>
        <router-link
            v-for="link in links"
            v-else
            :key="link.langIndex"
            :to="link.url"
        >
            <span class="d-flex">
                <img
                    :src="link.langName == 'Ro' ? '/images/ro.svg' : '/images/hu.svg'"
                    :alt="link.langName"
                >
                <p class="sm-sbold text">
                    {{ link.langName == 'Ro' ? 'Română' : 'Magyar' }}
                </p>
            </span>
        </router-link>
    </language-switcher>
</template>

<script>
    import ArrowLeftIcon from 'vue-feather-icons/icons/ArrowLeftIcon';
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import { mapState } from 'vuex';
    export default {
        name: 'LocaleSwitcher',
        components: {
            ArrowLeftIcon,
            CheckIcon,
        },
        props:{
            lang:{
                type: String,
                default: 'ro'
            }
        },
        computed: {
            ...mapState('general', ['isCustomHAF', 'maintenanceMode']),
            isMobile() {
                return this.$screen.breakpoint == 'md';
            },
        },
        methods: {
            isCurrent(lang) {
                return lang == this.lang;
            },
            closeDropdown() {
                this.$emit('closeDropdown');
            },
        },
    };
</script>
<style scoped lang="scss">
.router-language-switcher {
  @include media-breakpoint-up(md) {
    position: absolute;
    background-color: $white;
    box-shadow: $shadow-menu;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    top: 52px;
    left: -4px;
    z-index: 2;
    overflow: hidden;
    max-height: 0px;
    transition: max-height 150ms ease-in-out;
  }
  &.maintenance {
    @include media-breakpoint-down(lg) {
      right: 0;
      left: initial;
    }
  }

  a {
    span {
      padding: 4px 8px;
      @include media-breakpoint-up(md) {
        &:hover {
          background-color: $gray-thin;
          cursor: pointer;
        }
      }
      align-items: center;

      p {
        padding-top: 2px;
      }
    }
  }
}
</style>
