<template>
    <li
        v-click-outside="close"
        class="nav-item items lang d-flex"
        :class="{ opened: showLangDropdown }"
    >
        <div
            class="text d-flex"
            @click="showLangDropdown = !showLangDropdown"
        >
            <p class="sbold lang-text">
                {{ lang }}
            </p>
            <chevron-down-icon />
        </div>
        <language-switcher
            :lang="lang"
            @closeDropdown="close"
        />
    </li>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import ChevronDownIcon from 'vue-feather-icons/icons/ChevronDownIcon';
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapGetters } from 'vuex';

    import LanguageSwitcher from '../LanguageSwitcher';

    export default {
        name: 'LanguageItemNavbar',
        components: {
            LanguageSwitcher,
            ChevronDownIcon,
        },
        data() {
            return {
                showLangDropdown: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['isLogged']),
            lang() {
                return i18n.locale;
            },
        },
        methods: {
            ...mapActions('user', ['updateLanguage']),
            close() {
                this.showLangDropdown = false;
            },
        },
        watch: {
            lang() {
                this.close();
                if (this.isLogged) {
                    this.updateLanguage();
                }
            },
        },
        directives: {
            ClickOutside,
        },
    };
</script>
